<template>
  <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet">
  <PageContent>
    <SubHeading>Menu Management</SubHeading>
    <AdminBreadCrumb />
    <br>
    <div class="root">
      <div class="row heading">
        <div class="id">
          ID     
        </div>
        <div class="name">
          NAME         
        </div>
        <div class="link">
          Link         
        </div>
        <div class="buttons">
          Actions        
        </div>
      </div>
      <transition-group name="flip-list" tag="div">
      <div class="col" v-for="(item, index) in menu" :key="item.id" :class="{ edit: editIndex === item.id}">
        <div class="parent">
          <div class="id">
            <span>{{item.id}}</span>
          </div>
          <div class="name">
            <span v-if="editIndex !== item.id">{{item.name}}</span>
            <input type="text" v-model="item.name" v-if="editIndex === item.id" @input="updated"/>
          </div>
          <div class="link">
            <span v-if="editIndex !== item.id" transition="expand">{{item.link}}</span>
            <input type="text" v-model="item.link" v-if="editIndex === item.id" @input="updated"/>
          </div>
          <div class="buttons">
            <button @click="edit(item.id)" v-if="editIndex !== item.id">Edit</button>
            <i class="material-icons articleBtn" @click="showArticleList(item)" v-if="editIndex === item.id" >article</i>
            <i class="material-icons articleBtn" 
              @click="move(index, 'up')" v-if="editIndex === item.id" >arrow_upward</i>
            <i class="material-icons articleBtn" 
              @click="move(index, 'down')" v-if="editIndex === item.id" >arrow_downward</i>

            <button @click="edit(-1)" v-if="editIndex === item.id" class="view">OK</button>       
            <button @click="showSubMenu(item.id)" v-if="editIndex !== item.id && item.children && parentId != item.id" class="view">View Sub-Menu</button>
            <button @click="parentId = -1" v-if="editIndex !== item.id && item.children && parentId == item.id" class="view">Close Sub-Menu</button>
            <button @click="addChild(item)" v-if="editIndex !== item.id && !item.children">Add Sub-Menu</button>
            <button class="danger" v-if="editIndex !== item.id" @click="del(item.id)">DELETE</button>
          </div>
        </div>

        <div v-if="item.children && item.id === parentId">
          <transition-group name="flip-list" tag="div">
          <div class="child" v-for="(child, cIndex) in item.children" :key="child.id">
            <div class="id">
              <span>{{child.id}}</span>
            </div>
            <div class="name">
              <span v-if="editIndex !== child.id">{{child.name}}</span>
              <input type="text" v-model="child.name" v-if="editIndex === child.id" @input="updated"/>
            </div>
            <div class="link">
              <span v-if="editIndex !== child.id" transition="expand">{{child.link}}</span>
              <input type="text" v-model="child.link" v-if="editIndex === child.id" @input="updated"/>
            </div>
            <div class="buttons">
              <button @click="edit(child.id)" v-if="editIndex !== child.id">Edit</button>
              <i class="material-icons articleBtn" @click="showArticleList(child)" v-if="editIndex === child.id" >article</i>
              <i class="material-icons articleBtn" 
                @click="move(cIndex, 'up', item)" v-if="editIndex === child.id" >arrow_upward</i>
              <i class="material-icons articleBtn" 
                @click="move(cIndex, 'down', item)" v-if="editIndex === child.id" >arrow_downward</i>
              <button @click="edit(-1)" v-if="editIndex === child.id" class="view">OK</button>      
              <button class="danger" @click="del(child.id, item.id)">DELETE</button>
            </div>
          </div>
          </transition-group>
          <div>
            <button @click="addChild(item)">Add Row</button>
          </div>
        </div>

      </div>
      </transition-group>
      <hr>
      <button v-if="changed" class="save" @click="saveMenu">SAVE MENU!</button>
      <button v-if="!newInputs" @click="showNew">Add Menu Item</button>
      <div class="row" v-if="newInputs">
        <div class="name">
          Name:
          <input type="text" v-model="newMenuItem.name" />
        </div>
        <div class="link">
          Link:
          <input type="text" v-model="newMenuItem.link" />
        </div>
        <button @click="newInputs=false">Cancel</button>
        <button @click="addNewRootItem">Add</button>
      </div>
    </div>

    <Modal :open="showArticles" @close="closeArticleList"> 
      <template #header>Article List</template>
      <template #body> 
        <ArticleList :articles="articles" @clicked="articleClicked"/>
      </template>
    </Modal>
  </PageContent>
</template>

<style scoped>
  .row, .parent, .child {
    display: flex;
    flex-direction: row;
  }
  .col {
    display: flex;
    flex-direction: column;

    transition: all 0.6s cubic-bezier(0.23, 1, 0.32, 1);
  }
  .parent, .child {
    flex-direction: row;
    padding-bottom: 5px;
    padding-top: 5px;
    padding-left: 10px;
    flex: 1;
    transition: background-color 0.5s ease;
  }
  .child {
    background-color: rgba(0, 0, 0, 0.05);
  }
  .row.heading {
    flex-direction: row;
    background-color: darkslategray;
    border-radius: 5px;
    color: white;
    font-weight: bold;
    padding: 10px;
  }
  .id {
    min-width: 50px;
  }
  .name {
    min-width: 150px;
  }
  .name > input {
    width: 120px;
  }
  .link {
    min-width: 250px;
  }
  .buttons {
    flex: 1;
    display: flex;
    justify-content: space-between;
  }
  button {
    background-color: #4CAF50;
    border: none;
    color: white;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 1rem;
    margin-right: 10px;
    border-radius: 5px;
  }
  button.danger {
    background-color: #f44336;
  }

  .row.edit {
    background-color: rgba(0, 0, 0, 0.1);
  }
  button.save {
    animation: flash 1s infinite;
    transition: background-color 0.5s ease;
  }
  button.view {
    background-color: #008CBA;
  }
  .articleBtn{
    font-size: 2em;
    user-select: false;
    transition: all 0.2s;
    padding: 5px;
  }
  .articleBtn:hover {
    color: green;
  }
  button, .articleBtn{
    cursor: pointer;

    -webkit-user-select: none;  
    -moz-user-select: none;    
    -ms-user-select: none;      
    user-select: none;
  }
  .flip-list-move {
    transition: all 0.6s cubic-bezier(0.23, 1, 0.32, 1);
  }

  input{
    font-size: 1em;
    border-style: none none solid none;
    border-color: darkred;
  }
  input:focus {
    outline: none;
    background: rgba(0, 0, 0, 0.02);
  }


  @keyframes flash {
    0% {
      background-color: rgb(117, 209, 63);
    }
    100% {
      background-color: #4CAF50;
    }
  }
</style>

<script>
import PageContent from '@/components/PageContent'
import SubHeading from '@/components/SubHeading'
import Modal from '@/components/Modal'
import ArticleList from '@/components/ArticleList'
import AdminBreadCrumb from '@/components/Admin/AdminBreadCrumb'
import MenuService from '@/services/MenuService'
import ArticleService from '@/services/ArticleService'

export default {
  data() {
    return {
      menu: [],
      articles: [],
      editIndex: -1,
      changed: false,
      newInputs: false,
      parentId: -1,
      newMenuItem: {
        id: -1,
        name: '',
        link: ''
      },
      editItem: null,
      showArticles: false
    }
  },
  components: {
    PageContent,
    SubHeading,
    AdminBreadCrumb,
    Modal,
    ArticleList
  },
  async mounted() {
    this.menu = await MenuService.LoadRoot();
    this.articles = await ArticleService.getArticles();
  },
  methods: {
    edit(i){
      this.editIndex = i;
    },
    updated() {
      this.changed = true;
    },
    showNew() {
      const max = this.menu.reduce((r,l) => (r.id >= l.id) ? r : l);
      this.newMenuItem.id = max.id + 1;
      this.newMenuItem.name = '';
      this.newMenuItem.link = '';
      this.newInputs = true;
    },
    addNewRootItem() {
      this.menu.push({...this.newMenuItem});
      this.newInputs = false;
      this.changed = true;
    },
    async saveMenu() {
      await MenuService.Save(this.menu);
      this.changed = false;
      this.editIndex = -1;
    },
    addChild(parent){
      if (!parent.children) { parent.children = []; }
      const max = parent.children.length ? 
        parent.children.reduce((r,l) => (r.id >= l.id) ? r : l).id :
        parent.id;
      const newid = +(max + .1).toFixed(1);
      parent.children.push ({ id: newid, name: '', link: '' });
      this.changed = true;
      this.editIndex = newid;
    },
    showSubMenu(num) {
      this.parentId = num;
      this.editIndex = -1;
    },
    del(id, parentId){
      if (parentId) {
        var parent = this.menu.find(x => x.id === parentId);
        parent.children = parent.children.filter(x => x.id !== id);
        if (!parent.children.length) {
          delete parent.children;
        }
        return;
      }

      this.menu = this.menu.filter(x => x.id !== id);

      this.changed = true;
    },
    closeArticleList() {
      this.showArticles = false;
    },
    showArticleList(item) {
      this.editItem = item;
      this.showArticles = true;
    },
    articleClicked(name) {
      this.showArticles = false;
      this.editItem.link = `/doc/${name}`;
      this.changed = true;
    },
    move(index, direction, parent = null){
      if ((index === 0 && direction === 'up') || 
          direction === 'down' && index === (parent ? parent.children.length : this.menu.length) -1){
        return;
      }

      const newIndex = direction === 'up' ? index - 1 : index + 1;
      const copy = parent ? [...parent.children] : [...this.menu];
      const element = copy.splice(index, 1);
      copy.splice(newIndex,0,element[0]);
      
      if (parent){
        parent.children = copy;
      } else {
        this.menu = copy;
      }


      this.changed = true;
    }
  }
}
</script>