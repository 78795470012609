import axios from "axios";
import Config from '@/config/index.js'
import store from '@/store'
const LoadRoot = async () => {
  var result = await axios.get(`/data/menu.json`);
  return result.data;
}

const Save = async (menu) => {
  const token = store.getters.token;
  var result = await axios
  .post(
    `${Config.baseAPIPath}/SaveMenu`,
    menu,
    { 
      headers: { "Access-Control-Allow-Origin": "*", "Authorization": `Bearer ${token}` },
    }
  );
  return result.data;
}

export default {
  LoadRoot,
  Save
}